import { NextRouter } from 'next/router';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  MAXPRICERANGE,
  MINPRICERANGE,
} from '../../../constants/PriceRangeListContants';
import UplSelect from '../../atoms/UplSelect/UplSelect';
import UplText from '../../atoms/UplText/UplText';

interface UplPriceRangeSelectsProps {
  margin?: string;
  query?: {
    c?: string;
    t?: string;
    v?: string;
    min?: string;
    max?: string;
    pi?: string;
  };
  router: NextRouter;
  dataCy?: string;
}

const MinPriceSelect: FC<{
  router: NextRouter;
  value?: string;
}> = ({ router, value }) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    router.push({
      query: { ...router.query, min: e.target.value, pi: 0 },
    });
  };
  return (
    <UplSelect list={MINPRICERANGE} onChange={handleChange} value={value} />
  );
};

const MaxPriceSelect: FC<{ router: NextRouter; value?: string }> = ({
  router,
  value,
}) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    router.push({
      query: { ...router.query, max: e.target.value, pi: 0 },
    });
  };
  return (
    <UplSelect list={MAXPRICERANGE} onChange={handleChange} value={value} />
  );
};

const UplPriceRangeSelects: FC<UplPriceRangeSelectsProps> = ({
  margin,
  query,
  router,
  dataCy,
}) => {
  const style = `${margin}`;
  const [range, setRange] = useState<{
    min?: string;
    max?: string;
  }>({
    min: query?.min,
    max: query?.max,
  });

  useEffect(() => {
    setRange({
      min: query?.min,
      max: query?.max,
    });
  }, [query?.min, query?.max, setRange]);

  return (
    <div
      data-testid='UplPriceRangeSelects'
      className={style}
      data-cy={`price-select-container-${dataCy}`}
    >
      <div className='flex justify-between sm:flex-col'>
        <div
          className='w-full sm:px-2'
          data-cy={`low-price-select-container-${dataCy}`}
        >
          <div className='whitespace-nowrap'>
            <UplText variant='description'>下限価格</UplText>
          </div>
          <MinPriceSelect router={router} value={range.min} />
        </div>
        <div
          className='w-full sm:px-2'
          data-cy={`high-price-select-container-${dataCy}`}
        >
          <div className='whitespace-nowrap'>
            <UplText variant='description'>上限価格</UplText>
          </div>
          <MaxPriceSelect router={router} value={range.max} />
        </div>
      </div>
      {query?.max && query.min
        ? Number(query?.min) > Number(query?.max) && (
            <p className='mt-1'>
              <UplText variant='description' color='danger' bold='semibold'>
                上限価格は下限価格よりも大きな数を選択してください
              </UplText>
            </p>
          )
        : null}
    </div>
  );
};

export default UplPriceRangeSelects;
