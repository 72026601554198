import React, { ChangeEvent, FC } from 'react';

interface UplQuantityInputProps {
  quantity?: number | undefined;
  onChange?: (quantity?: number) => void;
  warning?: boolean;
}

const UplQuantityInput: FC<UplQuantityInputProps> = ({
  quantity,
  onChange,
  warning,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value = undefined } = e.target;
    if (onChange) {
      if (value) {
        const halfSizeValue = value.replace(/[^0-9]/g, (s) =>
          String.fromCharCode(s.charCodeAt(0) - 0xfee0),
        );
        const numberValue = Number(
          halfSizeValue.replace(/[^0-9]/g, '').slice(0, 4),
        );
        onChange(numberValue >= 10000 ? 9999 : numberValue);
      } else {
        onChange(undefined);
      }
    }
  };
  return (
    <div>
      <div className='flex items-center sm:justify-center sm:px-1 md:mx-auto md:justify-center md:pl-1'>
        <button
          type='button'
          onClick={() => onChange && onChange(quantity ? quantity - 1 : 1)}
          className='flex h-8 w-6 items-center justify-center rounded-l border border-r-0 border-dark-gray bg-dark-gray lg:hidden'
          disabled={!quantity || quantity <= 1}
        >
          <span className='block h-[2px] w-4 rounded-sm bg-white' />
        </button>
        <input
          type='text'
          className={`h-8 w-20 border text-center text-base leading-8 sm:w-14 sm:rounded-none md:w-14 md:rounded-none lg:mx-auto lg:rounded ${
            warning
              ? 'border-theme bg-theme-100 text-theme-700'
              : 'border-gray-200'
          }`}
          value={quantity}
          onChange={handleChange}
          maxLength={4}
          data-cy='quantity-input'
        />
        <button
          type='button'
          onClick={() => onChange && onChange(quantity ? quantity + 1 : 0)}
          className='flex h-8 w-6 items-center justify-center rounded-r border border-l-0 border-dark-gray bg-dark-gray lg:hidden'
          data-testid='addButton'
        >
          <span className='plus bg-white text-white' />
        </button>
      </div>
    </div>
  );
};
export default UplQuantityInput;
