import React from 'react';
import Image from 'next/image';
import cartIcon from '../../../public/Icon/shop/cart_w.png';
import UplText from '../UplText/UplText';

interface Props {
  openModal?: () => void;
  content?: string;
}

const UplCartIconButton: React.FC<Props> = ({ openModal, content }) => (
  <button
    type='button'
    onClick={openModal}
    className='flex h-8 items-center rounded-full bg-dark-gray p-2 sm:mt-2 sm:w-fit sm:rounded sm:px-3 md:mt-2 md:w-fit md:rounded md:px-3 lg:w-8'
    data-cy='cart-icon-button'
  >
    <Image src={cartIcon} alt='カート' width={20} height={20} />
    <span className='ml-2 hidden whitespace-nowrap sm:block md:block'>
      <UplText variant='description' color='white'>
        {content}
      </UplText>
    </span>
  </button>
);

export default UplCartIconButton;
