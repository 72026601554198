import React, { ComponentPropsWithoutRef, forwardRef, Fragment } from 'react';

interface UplSelectProps {
  list: {
    id: string;
    label: string;
    province?: string;
  }[];
  value?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  isError?: boolean;
}

type SelectProps = ComponentPropsWithoutRef<'select'> & UplSelectProps;

const UplSelect = forwardRef<HTMLSelectElement, SelectProps>(
  ({ list, value, name, onChange, isError, ...props }, ref) => {
    const style = `${
      isError && 'border-red'
    } p-2 px-3 ml-2 sm:ml-0 my-2 sm:w-full rounded-md border border-gray focus:border-theme-200 outline-none min:m-0`;

    return (
      <select
        {...props}
        data-testid='UplSelect'
        name={name}
        className={style}
        value={value || ''}
        onChange={onChange}
        ref={ref}
      >
        {list.map(({ id, label, province }) => (
          <Fragment key={id}>
            <option value={province}>{label}</option>
          </Fragment>
        ))}
      </select>
    );
  },
);
UplSelect.displayName = 'UplSelect';
export default UplSelect;
