import { FC, useState } from 'react';
import { MdCancel } from 'react-icons/md';
import Modal from 'react-modal';
import { useProductVariants } from '../../../hooks/product/useProduct';
import UplSpinner from '../../atoms/UplSpinner/UplSpinner';
import UplText from '../../atoms/UplText/UplText';
import UplProductDetail from '../UplProductDetail/UplProductDetail';

Modal.setAppElement('body');

interface Props {
  handle?: string;
  children: React.ReactNode;
}

const UplCartModal: FC<Props> = ({ handle, children }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [{ data, fetching }] = useProductVariants({
    handle: handle || '',
    pause: !modalIsOpen,
  });

  return (
    <>
      <div onClick={() => setModalIsOpen(true)} role='none'>
        {children}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className={{
          base: 'flex max-h-[90%] w-fit max-w-[95%] justify-center rounded-lg bg-white outline-none',
          afterOpen: 'animate-slideUp',
          beforeClose: 'animate-slideDown',
        }}
        overlayClassName={{
          base: 'fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full w-full bg-[rgba(0,0,0,0.5)] flex justify-center items-center z-40',
          afterOpen: 'animate-fadeIn',
          beforeClose: 'animate-fadeOut',
        }}
        data-cy='cart-modal'
      >
        {fetching && (
          <div className='relative bg-white p-20'>
            <UplSpinner />
          </div>
        )}
        {!fetching && !data?.productByHandle && (
          <div className='flex items-center justify-between p-10 text-center'>
            <UplText variant='text' color='danger' bold='semibold'>
              お探しの商品は削除されたか、存在しません。
            </UplText>
            <button
              onClick={() => setModalIsOpen(false)}
              className='ml-4 h-min cursor-pointer text-3xl text-gray-200 hover:text-dark-gray'
            >
              <MdCancel />
            </button>
          </div>
        )}
        {!fetching && data?.productByHandle && (
          <div
            className='relative z-40 overflow-y-auto p-8'
            data-cy='exist-product-cart-modal'
          >
            <div className='flex justify-between border-b border-gray bg-white pb-2'>
              <div>
                <UplText variant='h1'>{data?.productByHandle?.title}</UplText>
              </div>
              <button
                onClick={() => setModalIsOpen(false)}
                className='ml-4 h-min cursor-pointer text-3xl text-gray-200 hover:text-dark-gray'
              >
                <MdCancel />
              </button>
            </div>
            <div className='mt-4'>
              <UplProductDetail
                variants={data?.productByHandle?.variants}
                handle={handle}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default UplCartModal;
