import React, { FC } from 'react';
import UplText, {
  BoldTypes,
  ColorTypes,
  VariantTypes,
} from '../UplText/UplText';

interface Props {
  text: string;
  bgColor: string;
  color?: ColorTypes;
  bold?: BoldTypes;
  textVariant?: VariantTypes;
  py?: number;
  borderColor?: string;
}
const UplProductTag: FC<Props> = ({
  text,
  bgColor,
  color = 'white',
  bold = 'bold',
  textVariant = 'subtext',
  py = 0,
  borderColor = 'white',
}) => (
  <div
    // eslint-disable-next-line tailwindcss/no-custom-classname
    className={`bg-${bgColor} flex w-fit whitespace-nowrap rounded-r border-y-2 border-r-2 px-2 ${
      bgColor === 'white' ? 'rounded-l border-l-2' : ''
    } border-${borderColor} py-${py}`}
  >
    <UplText variant={textVariant} color={color} bold={bold}>
      {text}
    </UplText>
  </div>
);

export default UplProductTag;
