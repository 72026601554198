import React, { FC } from 'react';

const UplProductsSkeleton: FC = () => (
  <div className='w-full'>
    <div className='h-40 w-full animate-pulse bg-gray' />
    <div className='border border-gray p-3'>
      <div className='h-6 animate-pulse bg-gray' />
      <div className='mt-4 h-4 animate-pulse bg-gray' />
      <div className='mt-8 h-4 animate-pulse bg-gray' />
    </div>
  </div>
);

export default UplProductsSkeleton;
