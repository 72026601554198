export const MINPRICERANGE = [
  {
    id: '0',
    province: '0',
    label: '0',
  },
  {
    id: '1',
    province: '100',
    label: '100',
  },
  {
    id: '2',
    province: '500',
    label: '500',
  },
  {
    id: '3',
    province: '1000',
    label: '1,000',
  },
  {
    id: '4',
    province: '3000',
    label: '3,000',
  },
  {
    id: '5',
    province: '5000',
    label: '5,000',
  },
  {
    id: '6',
    province: '10000',
    label: '10,000',
  },
  {
    id: '7',
    province: '50000',
    label: '50,000',
  },
  {
    id: '8',
    province: '100000',
    label: '100,000',
  },
];

export const MAXPRICERANGE = [
  {
    id: '0',
    province: '',
    label: '制限なし',
  },
  {
    id: '1',
    province: '100',
    label: '100',
  },
  {
    id: '2',
    province: '500',
    label: '500',
  },
  {
    id: '3',
    province: '1000',
    label: '1,000',
  },
  {
    id: '4',
    province: '3000',
    label: '3,000',
  },
  {
    id: '5',
    province: '5000',
    label: '5,000',
  },
  {
    id: '6',
    province: '10000',
    label: '10,000',
  },
  {
    id: '7',
    province: '50000',
    label: '50,000',
  },
  {
    id: '8',
    province: '100000',
    label: '100,000',
  },
];
