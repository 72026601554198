import { Product } from '../types/shopify/products/Product.type';

export const PRODUCTS: Product[] = [
  {
    handle: 'ネジ',
    title: 'ネジ1',
    description: '一般的なネジ',
    productType: 'カテゴリA',
    id: '1',
    tags: [],
  },
];

export const HISTORYPRODUCTS = [
  {
    title: 'ソファー',
    description: 'ふかふかのソファーです',
    price: 12000080,
    category: 'カテゴリB',
    maker: '東京工業',
    history: 1,
    id: 17,
  },
  {
    title: '木工用接着剤',
    description: '接着剤',
    price: 1000,
    category: 'カテゴリA',
    maker: '東京工業',
    history: 2,
    id: 18,
  },
  {
    title: 'タイル用接着剤',
    description: '接着剤',
    price: 2000,
    category: 'カテゴリB',
    maker: '東京工業',
    history: 3,
    id: 19,
  },
  {
    title: '大ボルト',
    description: 'ボルト',
    price: 500,
    category: 'カテゴリC',
    maker: 'ケンセツ',
    history: 4,
    id: 20,
  },
  {
    title: '中ボルト',
    description: 'ボルト',
    price: 300,
    category: 'カテゴリD',
    maker: 'ケンセツ',
    history: 5,
    id: 21,
  },
];

export const ORDERPRODUCTS = [
  {
    id: 1,
    img: '/neji_1.jpg',
    title: '高級マットレス',
    price: 24000,
    option: 'S',
    quantity: 1,
  },
  {
    id: 2,
    img: '/neji_1.jpg',
    title: '高級マットレス',
    price: 24000,
    option: 'M',
    quantity: 1,
  },
  {
    id: 3,
    img: '/neji_1.jpg',
    title: '高級マットレス',
    price: 24000,
    option: 'L',
    quantity: 1,
  },
];

export const SORTVALUE = [
  {
    value: '',
    name: 'おすすめ順',
  },
  {
    value: 'r_l',
    name: '登録日(新しい順)',
  },
  {
    value: 'r_o',
    name: '登録日(古い順)',
  },
  {
    value: 'p_l',
    name: '価格(安い順)',
  },
  {
    value: 'p_h',
    name: '価格(高い順)',
  },
];

export const BESTPRODUCTS: Product[] = [];
