import { useMemo } from 'react';
import CollectionsModel from '../../models/top/CollectionsModel';
import { Edges } from '../../types/shopify/Edges.type';
import { Collection } from '../../types/shopify/products/Collection.type';
import { CampaignProps } from '../../constants/CategoryConstants';

export default function useCampaignProps(
  collections?: Edges<Collection>,
): CampaignProps[] | undefined {
  return useMemo(() => {
    const campaigns = (collections?.nodes || []).flatMap((collection) => {
      const isCampaignCollection = CollectionsModel.isCampaignCollection(
        collection.handle || '',
        'value',
      );
      return isCampaignCollection && collection.handle
        ? [collection.handle]
        : [];
    });
    return campaigns.length > 0
      ? campaigns.flatMap((campaign) => {
          const campaignCollectionProp =
            CollectionsModel.getCampaignCollectionProps(campaign, 'value');
          return campaignCollectionProp ? [campaignCollectionProp] : [];
        })
      : undefined;
  }, [collections]);
}
