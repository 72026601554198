import { FC, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { MdCancel } from 'react-icons/md';
import { Product } from '../../../types/shopify/products/Product.type';
import UplCartModal from '../UplCartModal/UplCartModal';
import { Edges } from '../../../types/shopify/Edges.type';
import { Collection } from '../../../types/shopify/products/Collection.type';
import UplPriceTemplate from '../../atoms/UplPriceTemplate/UplPriceTemplate';
import UplText from '../../atoms/UplText/UplText';
import UplTooltip from '../../atoms/UplTooltip/UplTooltip';
import UplCartIconButton from '../../atoms/UplCartIconButton/UplCartIconButton';
import UplProductTag from '../../atoms/UplProductTag/UplProductTag';
import useCampaignProps from '../../../hooks/product/useCampaignProps';

const UplProductTags: FC<{
  productTags?: string[];
}> = ({ productTags }) => {
  const targetTags = productTags?.slice(0, 2) || [];

  return (
    <div className='flex h-[50px] items-end'>
      <div className='flex flex-wrap-reverse  gap-2 px-3'>
        {targetTags.map((tag, i) => (
          <UplProductTag
            // eslint-disable-next-line react/no-array-index-key
            key={`${tag}-${i}`}
            text={tag}
            bgColor='white'
            textVariant='tiny'
            color='description'
            borderColor='white-gray'
          />
        ))}
      </div>
    </div>
  );
};

type Props = {
  item?: Product & { collections: Edges<Collection> };
  category?: string;
  handleDeleteClick?: (handle?: string) => void;
  displayDescription?: boolean;
};

interface UplFavoriteProductCardProps {
  item: Product & { collections: Edges<Collection> };
  onClick: (handle?: string) => void;
}
// カテゴリーが指定されていない場合、デフォルト値でタグの判別に使用
const DEFAULT_CATEGORY = '0000-00-00オススメ';
const UplProductCard: React.FC<Props> = ({
  item,
  category = DEFAULT_CATEGORY,
  handleDeleteClick,
  displayDescription,
}) => {
  const router = useRouter();

  const [hover, setHover] = useState(false);

  const campaignProps = useCampaignProps(item?.collections);

  const productCategory = category !== '' ? category : DEFAULT_CATEGORY;
  const displayedCampaignProps = campaignProps?.find(
    (campaignProp) => campaignProp.value === productCategory,
  );

  return (
    <div
      className={`relative w-full rounded border border-gray transition duration-100 ${
        hover ? '-translate-y-1 shadow-lg' : 'shadow-md'
      }`}
      data-cy='product-card'
    >
      <div
        className='cursor-pointer'
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onBlur={() => setHover(false)}
        onClick={() => router.push(`/product/${item?.handle}`)}
        aria-hidden
      >
        <div
          className={`relative flex h-44 items-center  ${
            item?.featuredImage ? '' : 'bg-gray'
          }`}
        >
          {item?.featuredImage?.url && (
            <Image
              alt='商品画像'
              src={item?.featuredImage?.url || ''}
              fill
              className='object-contain'
              unoptimized
            />
          )}
        </div>
        {campaignProps ? (
          <div className='absolute top-[160px] z-10'>
            {displayedCampaignProps ? (
              <UplProductTag
                text={displayedCampaignProps?.label || ''}
                bgColor={displayedCampaignProps?.color || ''}
                key={displayedCampaignProps?.label}
              />
            ) : (
              <UplProductTag
                text={campaignProps[0]?.label || ''}
                bgColor={campaignProps[0]?.color || ''}
                key={campaignProps[0]?.label}
              />
            )}
          </div>
        ) : null}
        <div className='p-3'>
          <div className='line-clamp-1'>
            <UplText variant='description'>{item?.vendor}</UplText>
          </div>
          <div className='h-12'>
            <h4
              className={`${hover && 'underline'} line-clamp-2`}
              data-cy='product-card-title'
            >
              <UplText variant='subtext' bold='semibold'>
                {item?.title}
              </UplText>
            </h4>
          </div>
          {displayDescription && (
            <div
              className={`${
                hover && 'underline'
              } mt-3 line-clamp-3 h-12 text-xs text-dark-gray`}
            >
              <UplText variant='description'>{item?.description}</UplText>
            </div>
          )}
        </div>
      </div>
      <div className='my-2'>
        <UplProductTags productTags={item?.productTags} />
      </div>
      {item?.priceRange && (
        <div
          className={`flex items-center justify-between px-4 pb-2 sm:flex-col md:flex-col ${
            displayDescription && 'mt-4'
          }`}
        >
          <div className='flex items-end'>
            <UplPriceTemplate
              price={{
                min: item?.priceRange?.minVariantPrice?.amount || 0,
              }}
            />
            <UplText variant='description' color='primary'>
              ~
            </UplText>
          </div>
          <UplCartModal handle={item.handle}>
            <UplTooltip content='カートに入れる'>
              <UplCartIconButton content='カートに入れる' />
            </UplTooltip>
          </UplCartModal>
        </div>
      )}
      {handleDeleteClick && (
        <button
          className='absolute right-0 top-0 size-10 cursor-pointer text-gray-300 hover:text-gray-200'
          onClick={() => {
            handleDeleteClick(item?.handle);
          }}
          data-cy='product-delete-button'
        >
          <div className='absolute right-1 top-1 cursor-pointer rounded-full bg-white text-3xl'>
            <MdCancel />
          </div>
        </button>
      )}
    </div>
  );
};

export const UplFavoriteProductCard: FC<UplFavoriteProductCardProps> = ({
  item,
  onClick,
}) => <UplProductCard item={item} handleDeleteClick={onClick} />;
export default UplProductCard;
